import React, {FC} from 'react';
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Listing from './Pages/Listing'


interface Props{
}

const App:FC<Props> = () => {
  return (
	<Router>
		<Routes>
			<Route path = "/" element={<Listing/>} />
		</Routes> 
	</Router>

  );
}

export default App;
