import React from 'react'
import { ICard } from '../Interfaces/ICard'
import { LazyLoadImage } from "react-lazy-load-image-component";


const Card:React.FC<ICard> = ({image, title, description, link, category, label, pubDate}) => {
  return (
    <div className="card">
      <LazyLoadImage src={image}
        alt={title}
      />
        <div className="card-body">
            <h5 className="card-title" style={{ fontSize : '15px', margin:'10px 0' }}>{title}</h5>
            <p className="card-text" style={{ fontSize:'14px' }}>{description} &nbsp;<a href={link} style={{ textDecoration:'none', fontSize:'14px' }}>Learn More</a></p>
            <div style={{display:'flex',alignItems:'center', justifyContent:'space-between'}}>
            <span className="category">{category}</span>
            {/* {label ? 
              <span className="label">{label}</span>
            : ''} */}

            </div>
            <div style={{fontSize:'12px'}}><i>Last update : {pubDate}</i></div>
        </div>
    </div>
  )
}

export default Card